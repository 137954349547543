.bf-landing-static .brianimpact-logo-small {
    display:block;
    height:52px;
    margin-top:25px;
    margin-left:60px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../static/brianimpact_logo.png)
}

.bf-landing-static {margin-top:70px}
/*.bf-landing-static .container {padding: 30px 74px 100px 74px;font-weight:500;}*/
.bf-landing-static .container {padding: 30px 4px 100px 4px;display:flex;flex-direction: column}
.bf-landing-static .title {font-size: 2.5em;margin:55px auto 0 auto;}
.bf-landing-static .bold {font-weight:700;}
.bf-landing-static .red {color:#d93025;}
.bf-landing-static .notice {font-size:1.125rem;max-width:770px;margin:65px auto 0 auto}
.bf-landing-static .bf_logo {
    margin-top:65px;height:205px;
    background-position: 50%;background-size: contain;
    background-repeat: no-repeat;
    background-image:url(../static/bf2023_wide.png)
}
.bf-landing-static .notice-box {display:flex;font-weight:400;margin-bottom:10px}
.bf-landing-static .notice-title {min-width:160px;text-align:right;font-weight:700}
.bf-landing-static .notice-content {margin-left:18px}
.bf-landing-static .notice-link {margin:10px 0; text-decoration: none; color:#28A0FF;font-weight:700}
.bf-landing-static .notice-content .go-apply-btn {margin-top:24px}
.bf-landing-static .go-share-btn {min-width:160px;min-height:68px;font-size:1.375rem;margin-left:16px;margin-top:24px}
.bf-landing-static .notice-content .desc {font-size: 0.875rem; color:#808080}
.bf-landing-static .notice-content.end {margin-top: 32px;font-size:1.75rem}

.bf-landing-static .go-link {min-width:220px;min-height:68px;font-size:1.375rem;margin-top:65px;padding:6px 16px;
    display:inline-flex;background:#1e2d37;color:#fff;text-decoration:none;justify-content:center;
    align-items:center;cursor:pointer}
.bf-landing-static .go-apply-btn {min-width:220px;min-height:68px;font-size:1.375rem;margin-top:65px}


@media all and (max-width: 600px) {
    .bf-landing-static .brianimpact-logo-small {height:42px;margin-top:16px;margin-left:20px;}
    .bf-landing-static .container {padding:30px 22px 100px 22px;}
    .bf-landing-static .title {font-size:1.5rem; margin:16px 0 0 0;}
    .bf-landing-static .notice {font-size:0.875rem;margin:36px 0 0 0;}
    .bf-landing-static .bf_logo {margin-top:66px;background-image:url(../static/bf2023_portrait.png)}
    .bf-landing-static .btn-container {display:flex;justify-content: center;}
    .bf-landing-static .go-apply-btn {min-width:160px;font-size:1.125rem;margin-right:10px;}
    .bf-landing-static .go-share-btn {min-width:160px;font-size:1.125rem;margin-left:0}
    .bf-landing-static .go-link {min-width:160px;font-size:1.125rem}
    .bf-landing-static .notice-title {min-width:60px}
    .bf-landing-static .notice-title.pc {display:none}
    .bf-landing-static .notice-content.end {margin-top: 32px;font-size:1.125rem}
}