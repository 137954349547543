.bf-signup {padding:0 20px;max-width:600px;}

.bf-signup .form {background-color: white;padding: 10px 14px 30px 30px;}

.bf-signup .form-item {
    background-color: white;
    padding-bottom: 16px;
    padding-right: 16px;
    margin-top: 0;
}

.bf-signup .form-item .btn {width: 100%; height: 56px;}
.bf-signup .form-item .fill {width:100%}
.bf-signup .form-item .chkbox { padding: 2px 9px; }
.bf-signup .error-msg { color: #ff0000; margin-left: 8px; font-size: 13px;}
.bf-signup .error-msg.agree {margin-left:28px;}
.bf-signup .agree-msg { font-size: 14px; }
.bf-signup .agree-msg a { color: #000000; }
.bf-signup .signup-ask { padding-right: 16px; margin-top: 12px;}
.bf-signup .signup-ask .mail { color: #000000; font-size:0.875rem;}
.bf-signup .logo {
    background: white url('../static/brianimpact_logo.png') no-repeat;
    height: 50px;
    background-size: auto 100%;
}

@media all and (max-width: 600px) {
    .bf-signup .logo {height: 38px;}
    .bf-signup .form {padding: 10px 6px 30px 16px;}
    .bf-signup .form-item {padding-bottom:10px;padding-right:10px;}
    .bf-signup .agree-msg {font-size:13px}
    .bf-signup .signup-ask {margin-top: 0;}
}