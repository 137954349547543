.ag-theme-alpine {
    --ag-font-size: 0.875rem;
    --ag-font-family: 'Noto Sans KR', sans-serif;
    --ag-header-background-color: #dfdfdf;

    --ag-header-column-separator-display: block;
    --ag-header-column-separator-color: #ffffff;
    --ag-cell-horizontal-border: 1px solid #dfdfdf;
    --ag-border-color: #dfdfdf;
}

.ag-theme-dgag-compact {
    --ag-header-height: 24px;
    --ag-row-height: 32px;
    --ag-font-size: 0.75rem;
    --ag-grid-size: 3px;

}

.ag-center-cols-clipper {
    min-height: unset !important;
}

.data-grid-ag-container .ag-header-row .ag-header-cell:last-child:after {display: none;}
.data-grid-ag-container .ag-body .ag-cell:last-child {border-right: none}
.data-grid-ag-container .ag-row:last-child {border-bottom: none}

.data-grid-ag-container .datagrid-btn-wrapper:not(:last-child) {margin-bottom:8px;}
.data-grid-ag-container .datagrid-ag-wrapper .header {background-color: #dfdfdf;}
.data-grid-ag-container .datagrid-ag-wrapper .header:not(:last-child) {border-color: #ffffff;}
.data-grid-ag-container .datagrid-ag-wrapper .no-rows-overlay {display:flex;justify-content:center;align-items:center;width:100%;height:100%;}

.data-grid-ag-container.compact .datagrid-ag-wrapper .dgag-table-cell {line-height:normal;padding-top:8px;padding-bottom:8px;}
.data-grid-ag-container.compact .datagrid-ag-wrapper .dgag-table-btncell .MuiSvgIcon-root {font-size: 1rem}

.data-grid-ag-container .datagrid-ag-wrapper .dgag-table-header.dark {background-color: #c0c0c0;}
.data-grid-ag-container .datagrid-ag-wrapper .dgag-table-header.dark .ag-header-cell-resize::after {background-color:#c0c0c0 !important;}
.data-grid-ag-container .datagrid-ag-wrapper .dgag-table-header.dark.ag-header-active {background-color: #c0c0c0 !important;}
.data-grid-ag-container .datagrid-ag-wrapper .dagag-table-cell.dark {background-color: #fcfaf0;}
