.share-sns-dlg .btn-logo {
    width:40px;height:40px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-width:40px;
}
.share-sns-dlg .kakao {background-image:url(../static/logo-kakaotalk.png)}
.share-sns-dlg .facebook {background-image:url(../static/logo-facebook.png)}
.share-sns-dlg .twitter {background-image:url(../static/logo-x.png)}
.share-sns-dlg .btn-container {padding:10px 16px}
.share-sns-dlg .btn-container .copy-btn {width:200px}
.share-sns-dlg .btn-container .copied {font-size:0.75rem;color:#808080}