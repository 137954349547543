.ag-theme-alpine {
    --ag-font-size: 0.875rem;
    --ag-font-family: 'Noto Sans KR', sans-serif;
    --ag-header-background-color: #dfdfdf;

    --ag-header-column-separator-display: block;
    --ag-header-column-separator-color: #ffffff;
    --ag-cell-horizontal-border: 1px solid #dfdfdf;
    --ag-border-color: #dfdfdf;
}

.ag-center-cols-clipper {
    min-height: unset !important;
}

.form-item-container .ag-header-row .ag-header-cell:last-child:after {display: none;}
.form-item-container .ag-body .ag-cell:last-child {border-right: none}
.form-item-container .ag-row:last-child {border-bottom: none}