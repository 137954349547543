/* @import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css); */
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 100;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf) format('opentype');}
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 300;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf) format('opentype');}
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 400;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf) format('opentype');}
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 500;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf) format('opentype');}
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 700;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf) format('opentype');}
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 900;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf) format('opentype');}

/* ToDo: remove important (css injection check) */
body, h1, h2, h3, h4, h5, h6, input, textarea, select {
  font-family: 'Noto Sans KR', sans-serif;
  /* font-family:'Spoqa Han Sans Neo','sans-serif'; */
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p,ul,ol,h1,h2,h3 { margin: 0;}


/* * { font-family: 'Spoqa Han Sans Neo', 'sans-serif'; } */
code {font-family: source-code-pro,Menlo,Monaco,Consolas,'Courier New',monospace;}

.bf-root { margin-left: 72px; margin-right: 72px;}

.bf-root .bf-header {height:70px;padding:0 60px;display:flex;flex-direction:row;align-items:center;box-shadow:none;
justify-content: space-between;}
.bf-root .bf-header .btn-ul { color: #000; font-size: 0.9375rem; font-weight: 700; }
.bf-root .bf-header .btn-ul:hover {background-color:#f7f7f7}
.bf-root .bf-header .btn-ul.selected {background-color:#f7f7f7}
.bf-root .bf-header .btn-user { margin-left: 30px; }

.footer { display: flex; height: 165px; font-weight: 500; align-items: center; margin-top: 100px;}
.footer .footer-contents { margin-left: 30px;}
.footer .policy a { font-size: 0.8125rem; color: #000;}
.footer .policy span:not(:last-child) { margin-right: 30px;}
.footer .footer-text { font-size: 0.6875rem; margin-top: 6px; }
.footer-mini { height: 100px;}

.hc-chip { font-size:0.875rem; font-weight:700; background-color:#dfdfdf; color:#000000; padding:8px 14px; text-align:center;}
.hc-chip.orange { background-color:#FF9100; color:#ffffff; }
.hc-chip.red { background-color:#FF0000; color:#ffffff; }

.brianimpact-logo-small {width:110px;height:50px;background-position: 50%;background-size: contain; background-repeat: no-repeat;
  background-image: url(../static/brianimpact_logo.png)}

.bf-global-dlg .dlg-title {padding: 24px 24px;}
.bf-global-dlg .dlg-content {padding: 0 24px 24px 24px;}
.bf-global-dlg .dlg-content-text {font-weight:500;}
.bf-global-dlg .dlg-actions {justify-content: center;padding: 24px 24px;}
.bf-global-dlg .dlg-actions .btn {min-width:120px;margin-left:24px;}
.bf-global-dlg .dlg-actions .btn:first-child {margin-left:0;}

/* page css */
.bf-root h1 { font-size: 1.875rem; font-weight: 700;}
.bf-root h2 { font-size: 1.375rem; font-weight: 700;}
.bf-root h3 { font-size: 1.125rem; font-weight: 700;}

.bf-root .title-text { font-size: 1.75rem; font-weight: 700; }
.bf-root .profile-info { font-size: 0.75rem; color:#a0a0a0; margin-left:6px; line-height:1.2}
.bf-document-home { margin-top: 70px; font-weight:500}

.bf-recommend-guide { font-size: 1rem; font-weight: 500; padding-top:20px;}

.bf-recommend-guide .title { font-size: 1.75rem; font-weight: 700; }
.bf-recommend-guide .guide-hello { margin-top: 30px; }
.bf-recommend-guide .guide-text { margin-top: 30px; background-color: #f7f7f7; padding: 20px 10px; }

.bf-recommend-list .guide-hr { background-color: #dfdfdf; height: 1px; border: 0; margin-top: 30px; }
.bf-recommend-list .guide-recomm { margin-top: 30px; }
.bf-recommend-list .guide-recomm .column {margin-bottom:20px;}
.bf-recommend-list .guide-recomm .guide-column-note {margin-top:6px;font-size:0.875rem;font-weight:500;}
.bf-recommend-list .guide-column-items { margin-top: 20px; padding-inline-start: 1.2em; }
.bf-recommend-list .grid-container { margin-top: 20px;}
.bf-recommend-list .bf-recommend-grid.hide { display: none; }
.bf-recommend-list .bf-recommend-grid .nom_date {font-size:14px;}
.bf-recommend-list-hr { margin: 30px 0; background-color: #dfdfdf; height: 1px; border: 0; }
.bf-recommend-list .grid-container .status-draft { background-color:#000; color:#fff; font-weight:700;}
.bf-recommend-list .grid-container .status-submitted { font-weight:700;}

.bf-application-guide {padding-top:20px;}
.bf-application-guide .guide-hello { font-size: 1.75rem; font-weight: 700; }

.bf-application-list {margin-top: 30px;}
.bf-application-list .list-container {margin-top:30px;}
.bf-application-list .guide-hr { background-color: #dfdfdf; height: 1px; border: 0; margin-top: 30px; }
.bf-application-list .desc-container {font-size: 1rem; font-weight: 500; margin: 20px 20px 20px 0; padding: 20px 20px 20px 0; background-color: #F7F7F7;}
.bf-application-list .desc-container .desc-note { margin: 20px 20px 0 20px; }
.bf-application-list .desc-container .status { font-weight: 700 }
.bf-application-list .desc-container .status.orange { color: #ff9100 }
.bf-application-list .desc-container .orange {color:#ff0000;font-weight:700}
.bf-application-list .desc-container .update-time { font-size: 0.875rem; font-weight: 500; color: #A0A0A0; }

.bf-document-home .go-container { margin-top: 30px; align-items: center; justify-content: center;}
.bf-document-home .go-container .title-text {font-size:1.375rem;}
.bf-document-home .go-container.hide { display: none; }
.bf-document-home .go-container .btn { font-size: 2.2rem; margin-left: 0; margin-right: 0;}
.bf-document-home .go-container .btn.orange { color: #ff9100; }

.form-header { padding:0 72px 0 72px; left:auto; right:0; top:70px; position:fixed; background-color: #fff;z-index: 1100; width:100%}
.form-header .form-appbar {height: 95px; }
.form-header .update-time {font-size: 0.75rem; font-weight: 500; color: #A0A0A0;}
.form-header .btn { height: 40px;font-weight:700}
.form-header .hc-chip {width: 80px; padding:4px 4px;}
.form-header-title { font-size:2.5rem; font-weight:700;}
.form-header-hr { background-color:#1E2D37; height:1px; border:0; margin-top:0; margin-bottom:0}
.form-header .form-header-info { align-items: center;}

.form-body .form-annotation {padding:10px; margin-top: 16px; margin-bottom: 16px; background-color: #f7f7f7;}
.form-body {margin-top: 185px;}
.form-body .header-desc {font-size: 0.9375rem; font-weight: 500; background-color:#1e2d38; color:#ffffff; padding: 20px 20px 20px 40px}
.form-body .form-section .section-title { margin-top: 20px; }
.form-body .form-section .section-desc {font-weight:500;font-size:0.9375rem;margin-top:12px;}
.form-body .form-body-item {margin-top: 25px;}
.form-body .form-item-title { font-size: 0.9375rem; font-weight: 500;}
.form-body .form-item-container { margin-top: 10px; display:flex; }
.form-body .form-item-validator .msg {font-size: 0.75rem; color: #ff0000; margin-top: 5px; margin-left: 5px; }

.form-body-item .form-item-required {color:#d93025}
.form-body-item .bf-form-hctable .header {background-color: #dfdfdf;}
.form-body-item .bf-form-hctable .header:not(:last-child) {border-color: #ffffff;}
.form-body-item .bf-form-hctable .no-rows-overlay {display:flex;justify-content:center;align-items:center;width:100%;height:100%;}
.form-body-item .bf-form-hctable .bf-form-hctable-cell.text {line-height:normal;padding:10px 17px;;white-space:pre-wrap}
.form-body-item .bf-form-hctable .bf-form-hctable-cell.ag-cell-wrap-text {line-height:normal;padding-top:4px;padding-bottom:4px;}
.form-body-item .bf-form-hctable .bf-form-hctable-btncell {padding-left:10px;padding-right:10px;}
.form-body-item .form-item-desc {font-size: 0.9375rem; font-weight: 500; margin-top:5px; margin-bottom:15px; color:#a0a0a0;}
.form-body-item div.form-item-desc {margin-left: 5px;}
.form-body-item .hc-check-depend .label {font-size: 0.9375rem; font-weight: 500;}
.form-body-item .no-data-item {width:100%;color:#a0a0a0;font-size:0.875rem;font-style:italic;padding:13px 10px;border:1px solid #dfdfdf;}
.form-body-item .bf-slider-container {padding: 30px 30px 0 30px;}

.form-step .step-header.below {margin-top: 20px;}
.form-step .step-header-desc {font-size: 0.9375rem; font-weight: 500; background-color:#1e2d38; color:#ffffff; padding: 20px 20px 20px 40px}
.form-step .step-header-desc.plain {padding: 20px;}
.form-step .step-title {margin-top: 30px; }

.bf-root .step-navi {margin-top:50px;align-items: center;}
.bf-root .step-navi .step-navi-btn {align-items: center;}
.bf-root .step-navi .btn {font-size: 2.2rem; color:#ff9100}
.bf-root .step-navi .title-text {font-size:1.375rem;}

.hc-table-edit .dlg-desc {margin-bottom: 10px;}
.hc-table-edit .col-container {margin-bottom: 20px;}
.hc-table-edit .col-title {font-weight:500; font-size: 1rem; margin-bottom: 4px;}
.hc-table-edit .col-desc {font-size: 0.8125rem; margin-bottom: 6px;}

.bf-center-container {
  position:absolute;top:0;right:0;bottom:0;left:0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  -moz-box-pack: center;
  -moz-box-align: center;
  -ms-box-pack: center;
  -ms-box-align: center;
  background: linear-gradient(to right, #F5CD1E, #FFA524);
}
.bf-center-container .header { padding: 18px 18px; background-color: #fff }

.bf-login {padding: 0 20px;width: 30rem;}
.bf-login .header {border-bottom: 2px solid black;}
.bf-login .logo {background: white url('../static/brianimpact_logo.png') no-repeat;height: 50px;background-size: auto 100%;}
.bf-login .form {background-color: white;padding: 30px;}

.bf-modal .bf-modal-content {margin-top:16px;}
.bf-modal .bf-modal-content .form-item {padding-bottom:20px;padding-right:10px;}
.bf-modal .bf-modal-content .form-item .btn {width:100%;height:56px;margin-right:16px;}
.bf-modal .bf-modal-content .form-item .btn:last-child {margin-right:0}
.bf-modal .form-container .error-msg {color:#ff0000;margin-bottom:16px;}

.bf-modal .bf-app-agree-terms {font-size:0.875rem;}
.bf-modal .bf-app-agree-terms .title {font-weight:700;margin-top:10px;}
.bf-modal .bf-app-agree-terms .desc2 {margin-top:10px;}

.dialog-only {background: linear-gradient(to right, #F5CD1E, #FFA524);}

.bf-root .splitview-container {display:flex;justify-content:space-between;margin-top:166px}
.bf-root .splitview-container .drawer {width: 50px; margin-right: 4px; padding-top: 20px; padding-left: 4px;}
.bf-root .splitview-container .form-body {margin-top:0}
.bf-root .splitview-left-container {overflow-y:auto; flex:6;padding:0}
.bf-root .splitview-right-container {overflow-y:auto; flex:4;padding:0 24px 32px 24px;background-color:#f7f7f7}
.bf-root .splitview-right-container .bf-score-info {margin-top:16px;margin-bottom:10px;text-align:right;font-weight:700;font-size:1.125rem;}
.bf-root .splitview-right-container .bf-score-info .score {color:#ff9100}

.bf-root .bf-eval-list-header {padding-top:16px;padding-bottom:32px;}
.bf-root .bf-eval-list-desc .txt {font-size:0.9375rem;color:#a0a0a0;font-weight:700;margin-top:10px;margin-bottom:16px;}
.bf-root .bf-eval-list-desc .num {color:#ff9100}
.bf-root .bf-embedview-menu-container {background-color:#f7f7f7;padding:16px;}
.bf-root .bf-embedview-menu-container .btn-ul { color: #000; font-size: 0.9375rem; font-weight: 700; }
.bf-root .bf-embedview-menu-container .btn-ul:hover {background-color:#d7d7d7}
.bf-root .bf-embedview-menu-container .btn-ul.selected {text-decoration:underline}
.bf-root .bf-embedview-container {padding:0 24px}
.bf-root .bf-embedview-cannot-view {font-size:0.9375rem;font-weight:500;padding:30px 30px;}

.bf-eval-list-header .bf-eval-final .desc {margin-top:30px;}
.bf-eval-list-header .bf-eval-final .baseline {margin-top:30px;}
.bf-eval-list-header .bf-eval-final .baseline-list {margin-top:20px;}
.bf-eval-list-header .bf-eval-final hr { background-color: #dfdfdf; height: 1px; border: 0; margin-top: 30px; }

.bf-recommend-list .grid-container .status-draft { background-color:#000; color:#fff; font-weight:700;}
.bf-recommend-list .grid-container .status-submitted { font-weight:700;}

.bf-document-home .bf-digging-note-guide {margin-top:110px; margin-bottom:30px;}
.bf-digging-note-guide h2 {font-size:1.75rem;margin-bottom:20px;}
.bf-digging-note-guide .title { font-size: 1.75rem; font-weight: 700; }
.bf-digging-note-guide .guide-hello { margin-top: 30px; }
.bf-digging-note-guide .guide-text { margin-top: 30px; background-color: #f7f7f7; padding: 20px 10px; }



@media all and (max-width: 600px) {
  .bf-root {margin-left:15px;margin-right:15px;}
  .bf-root .bf-header {padding-left: 15px;padding-right:15px;height:64px}
  .bf-root .bf-header .btn-user {margin-left:10px}
  .brianimpact-logo-small {width:35px;height:35px;background-image: url(../static/brianimpact_only_logo_black.png)}
  .bf-root h1 { font-size: 1.5rem;}
  .bf-root h2 { font-size: 1.125rem;}

  .bf-document-home {margin-top:64px;}
  .form-header {top:64px}
  .form-body {margin-top: 140px;}
  .hc-chip {font-size:0.75rem;}
  .bf-root .title-text {font-size:1.125rem;}
  .bf-document-home .go-container {margin-top:24px;}
  .bf-document-home .go-container .title-text {font-size:1.125rem;padding-left:0;padding-right:0}
  .bf-document-home .go-container .btn {font-size:2rem;}
  .bf-application-guide .guide-hello {font-size:1.125rem}
  .bf-application-guide .guide-hr {margin-top:24px}
  .bf-application-list {margin-top:24px;}
  .bf-application-list .desc-container {font-size:1rem}

  .bf-recommend-list .guide-recomm {font-weight:500}
  .bf-recommend-guide .guide-hello {margin-top:24px;}
  .bf-recommend-list .bf-recommend-grid .nominee-cell {font-size:0.75rem;}
  .bf-recommend-list .bf-recommend-grid .nominee-header {font-size:0.75rem;}

  .footer .footer-text {font-size:0.5rem;margin-top:4px}
  .footer .policy a {font-size:0.625rem}

  .form-header {padding:0 20px;}
  .form-header-title {font-size:1.75rem}
  .form-step .step-title {font-size:1.125rem}
  .form-body .form-section .section-title {font-size:1rem}
  .bf-root .step-navi .btn {font-size:2rem}
  .bf-root .step-navi .title-text {font-size:1.125rem}
  .bf-login .logo {height:38px}

  .form-body-item .bf-form-hctable .bf-form-hctable-cell {font-size:0.75rem;padding-left:4px;padding-right:4px;}
  .form-body-item .bf-form-hctable .bf-form-hctable-cell.text {padding-left:4px;padding-right:4px}

  .bf-modal .title {padding:16px 12px;}
  .bf-modal .container {padding:0 2px 24px 12px;}
  .bf-modal .bf-app-agree-terms {padding-right:10px;}
  .bf-modal .bf-app-agree-terms .title {padding:0;}
}
