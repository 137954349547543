/* for StepIndicator.js */
.form-header .step-ind-wrap {
    text-align: center;
    margin: 20px;
    position: relative;
    min-width: 150px;
    font-size: 15px;
}
.form-header .step-ind-wrap .links {
    display: flex;
    justify-content: space-between;
    position: relative;
}
.form-header .step-ind-wrap:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    border-top: 1px solid #dfdfdf;
    background: black;
    width: 100%;
    transform: translateY(-50%);
}
.form-header .step-ind-wrap .dot {
    width: 24px;
    height: 24px;
    background: #000;
    border-radius: 50%;
    color: #fff;
    margin-left: 16px;
    margin-right: 16px;
    font-weight: 700;
    /*display: flex;
    align-items: center;
    justify-content: center;*/
}
.form-header .step-ind-wrap .dot:first-child {
    margin-left: 0;
}
.form-header .step-ind-wrap .dot:last-child {
    margin-right: 0;
}
.form-header .step-ind-wrap .dot.active { background: #ff9100;}

@media all and (max-width: 600px) {
    .form-header .step-ind-wrap {margin:8px;min-width:100px;font-size:0.75rem;}
    .form-header .step-ind-wrap .dot {margin-left:4px;margin-right:4px}
}