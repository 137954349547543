@charset "utf-8";
.area_error {
    position: absolute;
    left: 50%;
    top: 49.2%;
    width: 100%;
    text-align: center;
    letter-spacing: -0.5px;
    transform: translate(-50%, -50%);
}

.tit_error {
    display: block;
    color: #222;
    font-size: 40px;
    line-height: 54px;
}

.desc_error {
    padding-top: 33px;
    color: #666;
    font-size: 20px;
    line-height: 36px;
}

.desc_error a { color: #ff9100; font-height: bold;}

.link_error {
    display: inline-block;
    margin-top: 56px;
    color: #ff9100;
    font-size: 22px;
    line-height: 36px;
    text-decoration: underline;
}

.doc-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px 0;
    text-align: center;
}

.doc-footer small {
    color: #a0a0a0;
    font-size: 12px;
    line-height: 14px;
}

.doc-footer a {
    text-decoration: none;
    color: #a0a0a0;
}

@media all and (max-width: 600px) {
    .area_error {
        top: 43.4%;
    }

    .tit_error {
        font-size: 20px;
        line-height: 30px;
    }

    .desc_error {
        padding-top: 16px;
        font-size: 15px;
        line-height: 24px;
    }

    .link_error {
        margin-top: 40px;
        font-size: 16px;
        line-height: 36px;
    }

    .doc-footer small {
        font-size: 10px;
    }
}